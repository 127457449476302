<template>
    <Modal>
        <h1>{{ user.firstname }} {{ user.lastname }}</h1>
        <p class="columns">
            <span>
                <small>Email</small><br>
                <strong>{{ user.email }}</strong>
            </span>
            <span class="align-left">
                <small>Nyhedsbreve</small><br>
                <strong>{{ user.mails ? 'Tilmeldt' : 'Ikke tilmeldt' }}</strong>
            </span>
        </p>
        <template #footer v-if="user.isAdmin">
            <ActionButton v-if="!isCurrentUser" :small="true" icon="shield" label="Fjern som admin" :red="true" @click="makeUserAdmin(false)"></ActionButton>
        </template>
        <template #footer v-else>
            <ActionButton :small="true" :secondary="true" icon="signout" label="Log ind" @click="loginAsUser(user.id)"></ActionButton>
            <ActionButton :small="true" :secondary="true" icon="shield" label="Gør til admin" :red="true" @click="makeUserAdmin(true)"></ActionButton>
        </template>
    </Modal>
</template>

<script>
import Modal from '@/components/Modal.vue';
import ActionButton from '@/components/buttons/ActionButton.vue';
import useState from '@/store';
import { useRoute } from 'vue-router';
import { reactive, toRefs } from 'vue';
import { auth, httpsRequest } from '@/utils/firebase';

export default {
    name: 'User',
    components: {
        Modal,
        ActionButton,
    },
    emits: ['unsubscribe'],
    setup() {
        const { modalData, initState } = useState();
        const hasLocalData = Object.entries(modalData.value).length;
        const route = useRoute();
        const data = reactive({
            user: {
                id: route.params.id,
                ...modalData.value,
            },
            isLoading: !hasLocalData,
        });

        return { ...toRefs(data), initState };
    },
    computed: {
        isCurrentUser() {
            return this.user.id === auth.currentUser.uid;
        },
    },
    methods: {
        async loginAsUser(uid) {
            try {
                const adminUid = auth.currentUser.uid;
                const createCustomToken = await httpsRequest.httpsCallable('createCustomToken');
                const response = await createCustomToken(uid);
                const userCredential = await auth.signInWithCustomToken(response.data.token);

                // TODO: Denne skal fjerne subscribe på Users.vue på sigt
                this.$emit('unsubscribe');
                await this.initState(userCredential.user.uid, adminUid);
                await this.$router.push('/');
            } catch (err) {
                console.log(err);
                console.log('Kunne ikke logge ind som bruger');
            }
        },
        async makeUserAdmin(admin) {
            const data = { uid: this.user.id, claims: { admin } };
            const setCustomClaimsDirectly = await httpsRequest.httpsCallable('setCustomClaims');
            await setCustomClaimsDirectly(data);
            this.user.isAdmin = admin;
            await auth.currentUser.getIdToken(true);
        },
    },
};
</script>

<style scoped lang="less">

</style>
